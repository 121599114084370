import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/routes";
import "./App.css";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";
import AuthContextProvider, {
  AuthContext,
} from "./contexts/AuthContextProvider";
import { Toaster } from "react-hot-toast";
import runOneSignal from "./utils/onesignal";
import ReactGA from "react-ga4";
import { checkVersion } from "./utils/versionCheck";

const App = () => {
  useEffect(() => {
    ReactGA.initialize("G-HJQ8R5J654"); // Initialize GA with your tracking ID
    ReactGA.send("pageview"); // Send a pageview event

    ReactGA.event({
      category: "User",
      action: "Clicked Button",
      label: "Home Page Button",
    });

    ReactGA.event({
      category: "Ecommerce",
      action: "Impression",
      label: "Product Name",
      value: "Product Price",
    });

    ReactGA.send("pageview");

    // Check version on app load
    checkVersion();

    // Add version check on visibility change
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        checkVersion();
      }
    });
  }, []);

  return (
    <AuthContextProvider>
      <Toaster reverseOrder={false} />
      <Router>
        <AppRoutes />
      </Router>
    </AuthContextProvider>
  );
};

export default App;
