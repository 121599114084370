// Version check utility
const VERSION_KEY = "sehool_version";
const CURRENT_VERSION = "3.0.0"; // Update this when you deploy new changes

export const checkVersion = () => {
  const storedVersion = localStorage.getItem(VERSION_KEY);

  if (storedVersion !== CURRENT_VERSION) {
    // Clear all caches
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }

    // Clear localStorage
    localStorage.clear();

    // Store new version
    localStorage.setItem(VERSION_KEY, CURRENT_VERSION);

    // Force reload
    window.location.reload(true);
  }
};

export const getCurrentVersion = () => CURRENT_VERSION;
