// SkeletonLoader.js
import React from 'react';

const ProductHomeSkeletonLoader = ({ h = 300 }) => {
    return (
        <div className={`skeleton-wrapper rounded-lg overflow-hidden h-[${h}px]`}>
            <div className="skeleton-cart"></div>
            <div className="skeleton-text"></div>
            <div className="skeleton-subtext"></div>
            <div className="skeleton-button"></div>
        </div>
    );
};

export default ProductHomeSkeletonLoader;
