import React, { useEffect, useState } from "react";
import axios from "axios";

function WhatsAppPage() {
  const [messageCount, setMessageCount] = useState(0);
  const [nextMessageTime, setNextMessageTime] = useState(null);
  const [error, setError] = useState(null);

  const messages = [
    " صورتك يا قمر 🌹",
    "محتاج صورة منك  💕",
    "وحشتيني.. ممكن صورة؟ 🥺",
    "عايز اشوف صورتك يا جميل 😍",
    "ابعتيلي صورة يا حياتي ❤️",
    "في شبه اكيد بين الوردة وبينك بس انا عايز صورتك انتي",
    "طيب بس صورة واحدة 🙏",
    "ما صورتك انتي؟",
    "وحشتني صورك.. ابعتيلي واحدة 💝",
    "عايز اشوفك.. صورة؟ 🌺",
  ];

  const getRandomMessage = () => {
    const randomIndex = Math.floor(Math.random() * messages.length);
    return messages[randomIndex];
  };

  const getRandomInterval = () => {
    return Math.floor(Math.random() * (120000 - 30000 + 1) + 30000);
  };

  const sendWhatsAppMessage = async () => {
    try {
      setError(null);
      // Using proxy URL (you need to set this up in package.json)
      const proxyUrl = "https://whatsapp.fatora.sd/send-message";

      // Method 1: Using proxy
      const response = await axios({
        method: "get",
        url: proxyUrl,
        params: {
          api_key: "aijQZatAsXOxodJZZ9Y2xF4ObpDHij",
          sender: "249915903708",
          number: "966505136350",
          message: getRandomMessage(),
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      // Alternative Method 2: Using direct CORS request
      /* const response = await fetch('https://whatsapp.fatora.sd/send-message?' + new URLSearchParams({
        api_key: "aijQZatAsXOxodJZZ9Y2xF4ObpDHij",
        sender: "249915903708",
        number: "966505136350",
        message: getRandomMessage(),
      }), {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      }); */

      console.log("Message sent successfully:", response.data);
      setMessageCount((prev) => prev + 1);

      // Set next message time
      const nextInterval = getRandomInterval();
      const nextTime = new Date(Date.now() + nextInterval);
      setNextMessageTime(nextTime);

      // Schedule next message
      setTimeout(sendWhatsAppMessage, nextInterval);
    } catch (error) {
      console.error("Error sending message:", error);
      setError("فشل في إرسال الرسالة - يرجى التحقق من الاتصال");
      // Retry after 5 seconds on error
      setTimeout(sendWhatsAppMessage, 5000);
    }
  };

  useEffect(() => {
    // Send initial message
    sendWhatsAppMessage();

    // Cleanup function
    return () => {
      // No need to clear timeout as it will be handled by the component unmount
    };
  }, []);

  // Format time remaining
  const getTimeRemaining = () => {
    if (!nextMessageTime) return "";
    const now = new Date();
    const diff = nextMessageTime - now;
    if (diff <= 0) return "جاري الإرسال...";
    const seconds = Math.floor(diff / 1000);
    return `الرسالة التالية خلال ${seconds} ثانية`;
  };

  return (
    <div className="p-4" dir="rtl">
      <h1 className="text-2xl font-bold mb-4">برنامج إرسال رسائل واتساب</h1>
      <div className="bg-gray-100 p-4 rounded-lg">
        <p className="text-lg">عدد الرسائل المرسلة: {messageCount}</p>
        <p className="text-sm text-gray-600 mt-2">{getTimeRemaining()}</p>
        {error && <p className="text-sm text-red-600 mt-2">{error}</p>}
      </div>
    </div>
  );
}

export default WhatsAppPage;
