import React, { useContext, useEffect, useState, useRef } from "react";
import Modal from "../../components/Modal";
import GeneralServices from "../../services/GeneralServices";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContextProvider";
import ProductHome from "./components/ProductHome";
import ProductHomeLg from "./components/ProductHomeLg";
import { showSuccessToast } from "../../utils/toastUtils";
import SlideshowAhmed from "../../components/slide/SlideshowAHmed";
import ProductComponent from "../List/components/ProductComponent";
import TabbyPromo from "../../components/TabbyPromo";
import ProductHomeSkeletonLoader from "./ProductHomeSkeletonLoader";
import { useInView } from "react-intersection-observer";

function HomeDesktop() {
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [mostSalesProducts, setMostSalesProducts] = useState([]);
  const { ref: aboutRef, inView: aboutInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    document.title = "سهول - الرئيسية";
    fetchData();
  }, []);

  const service = new GeneralServices();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const [categoriesRes, productsRes, mostSalesRes] = await Promise.all([
        service.get("categories"),
        service.get("products"),
        service.get("products?sort=sales"),
      ]);
      setCategories(categoriesRes);
      setProducts(productsRes.data);
      setMostSalesProducts(mostSalesRes.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="container mx-auto p-4">
        <div className="grid grid-cols-4 gap-4">
          {[...Array(8)].map((_, index) => (
            <ProductHomeSkeletonLoader key={index} />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div>
      {/* Categories */}
      <Categories categories={categories} />

      {/* Products */}
      <FeaturedProducts products={products} />

      <MostSalsProducts products={mostSalesProducts} />

      <div
        className="w-full h-[600px] bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: "url('/imges/slide.webp')",
        }}
      ></div>

      {/* About */}
      <div
        ref={aboutRef}
        className={`container transition-opacity duration-1000 ${
          aboutInView ? "opacity-100" : "opacity-0"
        }`}
      >
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <img
              src="imges/about.png"
              alt="عن سهول"
              loading="lazy"
              className="w-full h-auto"
            />
          </div>
          <div className="col-md-6 col-sm-12 py-2">
            <h1 className="font-bold">عن لحمه سهول</h1>
            <h1 className="md:text-7xl sm:text-xl font-bold my-3">
              نحن ننتج اجود اللحوم المتخاره لك
            </h1>

            <p className="font-medium">
              افضل امنواع الحوم المتواجد و جميع انواع اللحوم التي تحتاجة مثل لحم
              الغذال و البقر و الجمال
            </p>

            <div className="my-6">
              <ul className="space-y-2">
                <li>
                  <div className="flex items-center">
                    <img
                      src="icons/shield-tick.png"
                      alt="ضمان الجودة"
                      className="w-6 h-6"
                    />
                    <span className="mx-1">لحم طازج 100%</span>
                  </div>
                </li>
                <li>
                  <div className="flex items-center">
                    <img
                      src="icons/shield-tick.png"
                      alt="ضمان الجودة"
                      className="w-6 h-6"
                    />
                    <span className="mx-1">لحم طازج 100%</span>
                  </div>
                </li>
                <li>
                  <div className="flex items-center">
                    <img
                      src="icons/shield-tick.png"
                      alt="ضمان الجودة"
                      className="w-6 h-6"
                    />
                    <span className="mx-1">لحم طازج 100%</span>
                  </div>
                </li>
              </ul>
            </div>
            <button className="py-2 border bg-primaryColor text-white rounded px-8 hover:bg-primaryColor/90 transition-colors duration-200">
              <span>شاهد اكثر</span>
            </button>
          </div>
        </div>
      </div>

      {/* Videos Section */}
      <div className="container mt-4">
        <div className="row justify-between">
          <div className="col-md-6 col-sm-12 row justify-between">
            <img
              className="col-3"
              src="imges/video-list.png"
              alt="قائمة الفيديوهات"
              loading="lazy"
            />
            <img
              className="col-9"
              src="imges/video.png"
              alt="فيديو توضيحي"
              loading="lazy"
            />
          </div>
          <div className="col-md-6 col-sm-12 py-2">
            <h1>فيديوهات سهول</h1>
            <h1 className="md:text-7xl sm:text-2xl font-bold my-2">
              نعرض لك طريقة الذبح وشكل اللحوم
            </h1>

            <p className="font-medium">
              افضل طريقة للذبح الحلال و اجود طريقة لتغليف المنتجات التي تصل لك و
              اجود خدامات التوصيل
            </p>

            <div className="my-6">
              <ul className="space-y-2">
                <li>
                  <div className="flex items-center">
                    <img
                      src="icons/shield-tick.png"
                      alt="ضمان الجودة"
                      className="w-6 h-6"
                    />
                    <span className="mx-1">لحم طازج 100%</span>
                  </div>
                </li>
                <li>
                  <div className="flex items-center">
                    <img
                      src="icons/shield-tick.png"
                      alt="ضمان الجودة"
                      className="w-6 h-6"
                    />
                    <span className="mx-1">لحم طازج 100%</span>
                  </div>
                </li>
                <li>
                  <div className="flex items-center">
                    <img
                      src="icons/shield-tick.png"
                      alt="ضمان الجودة"
                      className="w-6 h-6"
                    />
                    <span className="mx-1">لحم طازج 100%</span>
                  </div>
                </li>
              </ul>
            </div>
            <button className="py-2 border bg-primaryColor text-white rounded px-8 hover:bg-primaryColor/90 transition-colors duration-200">
              <span>شاهد اكثر</span>
            </button>
          </div>
        </div>
      </div>

      <div className="w-full">
        <img
          src="imges/contact-bg.png"
          alt="خلفية التواصل"
          className="w-full"
          loading="lazy"
        />
      </div>
    </div>
  );
}

const FeaturedProducts = ({ products }) => {
  return (
    <div className="my-4 container">
      <div className="row justify-between container">
        <h1 className="text-3xl my-2 text-bold">المنتجات</h1>
        <Link
          to="list"
          className="row items-center hover:opacity-80 transition-opacity duration-200"
        >
          <h1 className="text-primaryColor">شاهد اكثر</h1>
          <img className="h-8" src="icons/more.png" alt="المزيد" />
        </Link>
      </div>
      <div className="row">
        {products && (
          <>
            <div className="row col-md-6 col-sm-12">
              {products[0] && (
                <ProductComponent
                  key={products[1].id}
                  col={`col-6 col-md-6 col-sm-6 col-md-6 xl:col-sx-6`}
                  data={products[0]}
                />
              )}
              {products[1] && (
                <ProductComponent
                  key={products[2].id}
                  col={`col-6 col-md-6 col-sm-6 col-sx-6`}
                  data={products[1]}
                />
              )}
              {products[2] && (
                <ProductComponent
                  key={products[3].id}
                  col={`col-6 col-md-6 col-sm-6 col-sx-6`}
                  data={products[2]}
                />
              )}
              {products[3] && (
                <ProductComponent
                  key={products[0].id}
                  col={`col-6 col-md-6 col-sm-6 col-sx-6`}
                  data={products[3]}
                />
              )}
            </div>
            <div className="col-md-6 col-sm-12">
              {products[4] && <ProductHomeLg data={products[4]} />}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const MostSalsProducts = ({ products }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div
      ref={ref}
      style={{ backgroundColor: "rgba(1,1,1,0.8)" }}
      className={`w-full py-3 transition-opacity duration-1000 ${
        inView ? "opacity-100" : "opacity-0"
      }`}
    >
      <div className="container">
        <h1 className="text-bold text-2xl text-white">الاكثر مبيعا</h1>
      </div>

      <div className="row overflow-x-scroll scroll-smooth scrollbar-thin scrollbar-hidden justify-evenly p-2">
        {products.map((product, index) => {
          if (index > 4) return;
          return <MostSalsProduct data={product} key={index} />;
        })}
      </div>
      <div className="row justify-center mt-3">
        <img
          src="icons/right-arrow.png"
          alt="السابق"
          className="cursor-pointer hover:opacity-80 transition-opacity duration-200"
        />
        <img
          src="icons/left-arrow.png"
          alt="التالي"
          className="cursor-pointer hover:opacity-80 transition-opacity duration-200"
        />
      </div>
    </div>
  );
};

const Categories = ({ categories }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -300,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 300,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="container  py-8 relative">
      <h2 className="text-3xl font-bold text-center mb-8">تصنيفاتنا</h2>

      {/* Navigation Arrows */}
      <div className="absolute left-0 right-0 flex justify-between items-center px-4">
        <button
          onClick={scrollLeft}
          className="bg-white/80 hover:bg-white text-primaryColor p-2 rounded-full shadow-lg transition-all duration-300 hover:scale-110 z-10"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
        <button
          onClick={scrollRight}
          className="bg-white/80 hover:bg-white text-primaryColor p-2 rounded-full shadow-lg transition-all duration-300 hover:scale-110 z-10"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>

      <div
        ref={ref}
        className={`relative transition-all duration-1000 ${
          inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
        }`}
      >
        <div
          ref={scrollContainerRef}
          className="flex overflow-x-auto gap-6 py-4 px-2 scrollbar-hide"
          style={{
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          {categories &&
            categories.map((category, index) => (
              <Category data={category} key={index} index={index} />
            ))}
        </div>
      </div>
    </div>
  );
};

const Category = ({ data, index }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Link
      to={`list`}
      className={`relative overflow-hidden rounded-xl shadow-lg transition-all duration-300 transform hover:scale-105 hover:shadow-xl min-w-[280px] ${
        isHovered ? "ring-2 ring-primaryColor" : ""
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${data.image}')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "300px",
      }}
    >
      <div className="absolute inset-0 flex flex-col items-center justify-center p-6 text-center">
        <div className="mb-4 transform transition-transform duration-300 hover:scale-110">
          <img
            className="w-24 h-24 object-cover rounded-full border-4 border-white shadow-lg"
            src={data.image}
            alt={data.name}
            loading="lazy"
          />
        </div>
        <h3 className="text-2xl font-bold text-white mb-2">{data.name}</h3>
        <div
          className="absolute bottom-0 left-0 right-0 h-1 bg-primaryColor transform origin-left transition-transform duration-300"
          style={{ transform: isHovered ? "scaleX(1)" : "scaleX(0)" }}
        />
      </div>

      {/* Hover overlay */}
      <div
        className={`absolute inset-0 bg-gradient-to-t from-black/80 to-transparent transition-opacity duration-300 ${
          isHovered ? "opacity-100" : "opacity-0"
        }`}
      >
        <div className="absolute bottom-0 left-0 right-0 p-4 text-white">
          <p
            className="text-sm opacity-0 transition-opacity duration-300"
            style={{ opacity: isHovered ? 1 : 0 }}
          >
            اكتشف منتجاتنا المميزة في هذه الفئة
          </p>
        </div>
      </div>
    </Link>
  );
};

const MostSalsProduct = ({ data }) => {
  const { card, setCard } = useContext(AuthContext);

  const handleAddToCard = () => {
    const isProductExist =
      card.products && card.products.some((product) => product.id === data.id);

    if (isProductExist) {
      const updatedProducts = card.products.map((product) =>
        product.id === data.id
          ? { ...product, quantity: (product.quantity || 1) + 1 }
          : product
      );
      setCard((prevCard) => ({ ...prevCard, products: updatedProducts }));
    } else {
      const updatedCard = {
        ...card,
        products: card.products
          ? [...card.products, { ...data, quantity: 1 }]
          : [{ ...data, quantity: 1 }],
      };
      setCard(updatedCard);
    }

    // show toast
    showSuccessToast("تمت اضافة المنتج");
  };

  // check if prodect in

  return (
    <div
      className="col-md-2 col-ms-12 sm:m-2  relative rounded-lg overflow-hidden ml-2 mt-3"
      style={{ backgroundColor: "rgba(225,225,225,0.1)" }}
    >
      <div className="bg-rose-500 max-w-fit p-1 absolute left-0 rounded-br-lg text-white block">
        {" "}
        {data.label}
      </div>
      <div className="justify-center px-1 py-2 flex w-full">
        <img
          className="w-full h-72  rounded-lg object-cover"
          // src="https://www.eatthis.com/wp-content/uploads/sites/4/2023/05/MEAT.jpg"
          src={data?.image ?? "/imges/slide-4.jpg"}
          alt={data.name}
        />
      </div>

      <div className="text-white container">
        <Link to={`/products-details/${data.id}`}>
          <h className="text-[20px] text-bold">{data.name}</h>
        </Link>
        {data.description
          .split("\n")
          .slice(0, 2)
          .join("\n")
          .split("\n")
          .map((item, index) => {
            return <p key={index}>{item}</p>;
          })}
        <h className="text-bold text-[20px]">
          {((data.price || 0) * 1.15).toFixed(0)}
          ريال
        </h>
        <div className="row justify-between p-2">
          <div
            onClick={() => handleAddToCard(data)}
            className=" p-1 rounded flex items-center cursor-pointer bg-rose-500 text-base text-white"
          >
            <img
              width="25"
              height="25"
              className="mx-1"
              src="https://img.icons8.com/ios-filled/50/ffffff/add-shopping-cart--v1.png"
            />
            <span> اضافة للسله</span>
          </div>
          <div className="row items-center justify-center">
            <span className="font-semibold mt-1 mx-1">{data.rating}</span>
            <img src="icons/star_rating.png" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeDesktop;
